<template>
    <div class="banner">
        <div class="filter-container">
            <input type="text" placeholder="Search" v-model="search" @keyup="$emit('search', search)" class="btn-search">
            <select class="btn-websites" v-model="websites" @change="$emit('websites', websites)">
                <option value="0">All websites</option>
                <option value="1">Safe Group</option>
                <option value="2">Safe Orthopaedics</option>
                <option value="3">Safe Medical</option>
                <option value="4">LinkedIn</option>
            </select>
        </div>
        <router-link class="btn" :to="{ name : 'AddForm', params :{ cat : cat}}">Add</router-link>
    </div>
</template>

<script>
export default {
    name : "BannerAction",
    props : ["cat"],
    data() {
        return {
            search : null,
            websites : 0
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn-search, .btn-search::placeholder {
        color:#fff;
        margin-right:20px;
    }

    .btn {
        width:150px;
        text-transform: uppercase;
        text-align: center;
        padding:11px 25px;
    }

    .btn-websites {
        min-width: 250px;
    }
</style>