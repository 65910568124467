<template>
    <banner-action
        :cat=cat
        type="full"
        v-on:search="search"
        v-on:websites="websitesFilter"
    >
    </banner-action>
    <div class="table-container">
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Sites</th>
                    <th>Languages</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in listFilter" :key="idx"> 
                    <td>{{new Date(item.published).toLocaleDateString("EN-en", { year: 'numeric', month: '2-digit', day: '2-digit' })}}</td>
                    <td>
                        <img :src="`${$store.state.assetsUrl}/admin-${item.imgFeatured}`" class="img-thumb"  />
                    </td>
                    <td v-html="getTitle(item)"></td>
                    <td>{{ getType(item.type) }}</td>
                    <td v-html="getWebsites(item.websites)"></td>
                    <td class="langs">{{getLangs(item.langs)}}</td>
                    <td>
                        <div class="actions-container">
                            <router-link :to="{ name : 'UpdateForm', params : { post : item._id}}" class="post-btn icon-otw_icon_edit"></router-link>
                            <div class="post-btn icon-otw_icon_trash" @click="confirmId = item._id"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <confirm-delete 
        v-if="confirmId"
        :id="confirmId"
        v-on:close="confirmId = null"  
        v-on:confirmDelete="confirmDelete"  
    ></confirm-delete>
</template>

<script>
import axios from "axios";
import BannerAction from "./BannerAction.vue";
import confirmDelete from "./ConfirmDelete.vue";

export default {
    name : "PostsTable",
    components : { BannerAction, confirmDelete },
    data() {
        return {
            list : [],
            searchStr : null,
            websites : null,
            confirmId : null
        }
    },
    props : ["cat"],
    methods : {
        getData() {
            let config = {
                validateStatus: () => true,  
            };
            config.headers = {  'x-access-token' : localStorage._safeToken }
            let vm = this;
                axios
                    .get(`${this.$store.state.apiUrl}/posts/${this.cat}`,  config)
                    .then(function (res) {                
                        if(res.status != 200) {
                            const notice = {
                                msg : res.data.errors,
                                error : true
                            }
                            vm.$store.commit('setNotice', notice);                   
                        }else{
                            vm.list = res.data.message; 
                       
                        }
                    });
        },
        getType(type) {
            if(type == 1) {
                return "Standard";
            }else if(type == 2){
                return "Document";
            }else {
                return "External Link";
            }
        },
        getLangs(langs) {
            let res = "";
            langs.forEach(element => {
                res += element  + " | ";  
            });
            return res.substring(0, res.length - 2);
        },
        getWebsites(sites) {
            let res = "";
            if(sites.includes(1)) {
                res += "Safe Group<br />";
            }
            if(sites.includes(2)) {
                res += "Safe Orthopaedics<br />";
            }
            if(sites.includes(3)) {
                res += "Safe Medical<br />";
            }
            if(sites.includes(4)) {
                res += "LinkedIn<br />";
            }
            return res;            
        },
        getTitle(item) {
            let res = "";
            if(item.langs.includes("en") && item.en.title) {
                res += item.en.title + "<br />";
            }
            if(item.langs.includes("fr") && item.fr.title) {
                res += item.fr.title + "<br />";
            }
            if(item.langs.includes("de") && item.de.title) {
                res += item.de.title + "<br />";
            }
            return res;
        },
        search(data) {
            this.searchStr = data;
        }, 
        websitesFilter(data) {
            this.websites = data;
        }, 
        switchSite(site) {
            this.sites.includes(site)  ? this.sites = this.sites.filter(e => e != site) : this.sites.push(site);
            
        },
        confirmDelete(_id) {
            this.confirmId = null;
            this.list = this.list.filter(e => e._id != _id);
        }
    },
    mounted() {
        this.getData();
    },
    computed : {
        listFilter() {
            if(this.searchStr || ( this.websites && this.websites != 0) ) {
                let filters = this.list;
                if(this.websites && this.websites != 0) {
                    filters = this.list.filter(e => e.websites.includes(parseInt(this.websites)) );
                }
                if(this.searchStr) {
                    filters = filters.filter(e => {
                        const dateSearch = new Date(e.published).toLocaleString("EN-en", { year: 'numeric', month: '2-digit', day: '2-digit' }); 
                        console.log('date pub ' + dateSearch)
                        //search on title
                        if(e.langs.includes("en") && e.langs.includes("fr") && e.langs.includes("de")) {
                            return e.en.title.toLowerCase().includes(this.searchStr.toLowerCase()) || e.fr.title.toLowerCase().includes(this.searchStr.toLowerCase()) || e.de.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        }
                        else if (e.langs.includes("en") && e.langs.includes("fr")) {
                            return e.en.title.toLowerCase().includes(this.searchStr.toLowerCase()) || e.fr.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        }
                        else if (e.langs.includes("fr") && e.langs.includes("de")) {
                            return e.fr.title.toLowerCase().includes(this.searchStr.toLowerCase()) || e.de.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        }else if (e.langs.includes("en") && e.langs.includes("de")) {
                            return e.en.title.toLowerCase().includes(this.searchStr.toLowerCase()) || e.de.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        }
                        else if(e.langs.includes("en")) {
                            return e.en.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        } 
                        else if(e.langs.includes("fr")) {
                            return e.fr.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        } 
                        else if(e.langs.includes("de")) {
                            return e.de.title.toLowerCase().includes(this.searchStr.toLowerCase()) || dateSearch.includes(this.searchStr);
                        } 
                    });
                }
                return filters;
            }else {
                return this.list;
            }
             
        }
    },
}
</script>

<style scoped lang="scss">
    .img-thumb {
        height:50px;
        width:89px;
    }

    .langs {
        text-transform: uppercase;
    }

    .filters-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:20px 0;
    }

    h3 {
        color:#fff;
        font-weight:300;
        margin: 0 0 10px 0;
    }

    .filters-row {
        display: flex;
        justify-content: center;
        width:100%
    }

    .type-item {
        width:120px;
        margin:10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .type-label {
        margin-top:10px;
        color:#fff;
        text-align: center;
        font-size:15px;
        font-weight:300;
    }

    .checkbox-input {
        width:16px;
        height:16px;
        border:2px solid #fff;
        cursor: pointer;
        transition: 0.5s;
        background:#fff;
    }

    .checkbox-input.selected {
        background:$mainColor;
    }
</style>