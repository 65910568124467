<template>
    <div class="modal">
        <div class="modal-close icon-otw_icon_close " @click="$emit('close')"></div>
        <div class="modal-container">
            <h3>Please confirm deletion {{ typeName }}</h3>
            <div class="btns-container">
                <div class="btn btn-cancel" @click="$emit('close')">Cancel</div>
                <div class="btn btn-confirm" @click="deletePost()">Confirm</div>
            </div>
            <div class="notices errors" v-if="errors">An error has occured : <br />{{ errors}}</div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name : "confirmDelete",
    props : ["id", "type"],
    data() {
        return {
            errors : null,
            typeName : null
        }
    },
    methods : {
        deletePost() {
            this.errrors = null;
            let config = {
                validateStatus: () => true,  
            };
            config.headers = {  'x-access-token' : localStorage._safeToken }
            let vm = this;
            if(this.type == "event") {
                axios
                    .delete(`${this.$store.state.apiUrl}/event/${this.id}`,  config)
                    .then(function (res) {                
                        if(res.status != 200) {
                            vm.errors = res.status.errors
                        }else{
                            const notice = {
                                msg : res.data.message,
                                error : false
                            }
                            vm.$store.commit('setNotice', notice);
                            vm.$emit('confirmDelete', vm.id);
                        }
                    });
            }else {
                axios
                    .delete(`${this.$store.state.apiUrl}/post/${this.id}`,  config)
                    .then(function (res) {                
                        if(res.status != 200) {
                            vm.errors = res.status.errors
                        }else{
                            const notice = {
                                msg : res.data.message,
                                error : false
                            }
                            vm.$store.commit('setNotice', notice);
                            vm.$emit('confirmDelete', vm.id);
                        }
                    });
            }
        }
    },
    mounted() {
        if(this.type == "event") {
            this.typeName = "Event"
        }else {
            this.typeName = "Post"
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:1;
        background:rgba(255,255,255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-container {
        padding:40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background:#000;
        width:500px;
    }

    h3 {
        font-size:20px;
        color:#fff;
        text-align: center;
        font-weight: 300;
    }

    .btns-container {
        display: flex;
        margin-top:20px;
    }

    .btn {
        margin:0 20px;
    }

    .btn-cancel {
        background:#000;
        border:1px solid #fff;
    }

    .btn-cancel:hover {
        background:#fff;
        color:#000;
    }
    
</style>